.form__foot .list--buttons {
  display: flex;
  list-style: none;
  padding: 0;
}
.form__foot .btn__success {
  margin-right: 50px;
}
.link-btn {
  border: none;
  background-color: transparent;
}
.btn__success {
  background-color: #4065ff;
  border: none;
  color: #fff;
  padding: 1rem 2.5rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.button__outline {
  background-color: transparent !important;
  padding: 0.8rem 2.5rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: 20px;
}
.ico-plus-circle {
  margin-right: 10px;
}
li {
  cursor: default;
}

.tableRowCustom > td {
  border-color: rgb(0, 0, 0, 0.3);
  border-style: solid;
  border-width: 1px;
  padding: 5px;
}
.tableRowCustom > td input{
  width: 90%;
}

.tableRowCustom > td:nth-child(1) {
  border-left-color: #fff;
}
.tableRowCustom > td:nth-child(5) {
  border-right-color: #fff;
}
.paddingBottom {
  padding-bottom: 30px;
}

.paddingBottom > .form__label {
  padding-bottom: 5px;
}
.tableRowCustom > td > input {
  border: none;
}
.accordion-item , .accordion-item:first-of-type{
  border: none !important;
}
.accordion-button {
  width: 50% !important;
}
